<template>
    <div class="container">
			<ConnectionLost />
		</div>
</template>
<script>
import ConnectionLost from '@/components/ConnectionLost.vue'

export default{
  components: {
    ConnectionLost
  },
  mounted(){
    const category = this.$route.params.category

    this.setHeader('page', {
        titulo: 'Sin Conexión'
    })
  }
}
</script>
